import {useFormik} from 'formik';
import * as Yup from 'yup';
import React, { Fragment } from 'react';
import './CreateD.scss';
import paymentDetails from '../../../Common/constants';

const CreateD = ({transactionHashSubmitted}) => {
  const { walletAddress, cryptoAmount } = paymentDetails;
  const transactionHashRegExp = /^0x([A-Fa-f0-9]{64})$/;

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
  }

  const backToWhitelistDetails = () => {
    transactionHashSubmitted(50, true, false,false, false);
  };
  
  const formik = useFormik({
    initialValues: {
      transactionHash: sessionStorage.getItem('transactionHash') || ''
    },
    validationSchema: Yup.object({
      transactionHash: Yup.string().required('*Required').matches(transactionHashRegExp, 'Invalid transaction hash or ID'),
    }),
    onSubmit: values => {
      sessionStorage.setItem('transactionHash', values.transactionHash);
      transactionHashSubmitted(100, true, true,true, false);
    },
  });

  return (
    <Fragment>
      <section id='create-d' className='text-center py-3'>
      <div className='container'>
        <h2 className='section-title'>Contract Fee Payment</h2>
        <div className='bottom-line'></div>
        <div>
            <p>SEND THE AMOUNT TO THIS WALLET ADDRESS :</p>
            <ul>
              <li>Amount : <span className='bnb-amount'>{ cryptoAmount }</span></li>
              <li>Wallet Address <b>(Binance Smart Chain) :</b></li>
            </ul>
            <p>
              <span className='wallet'>{ walletAddress }</span>
              <button type='button' className='btn-main copy' onClick={copyAddressToClipboard}>COPY</button>
            </p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='text-fields'>
            <div className='text-input ' >
              <label htmlFor="transactionHash" className="required"><b>Your Transaction Hash / ID</b></label>
              <input
                type='text'
                name='transactionHash'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.transactionHash}
                className='transaction-input'
                placeholder='0x8287b353321d67b1ef8991eed85041ffbda8e41af63b574df4f5ef6781af6865' /> 
                {formik.touched.transactionHash && formik.errors.transactionHash ?
                  (<div className='warning'> {formik.errors.transactionHash} </div>) : null
                }
            </div>
          </div>
          <div className='control-buttons'>
            <button type='button' className='btn-dark direction' onClick={backToWhitelistDetails}>PREVIOUS</button>
            <button type='submit' className='btn-main direction' >NEXT</button>
          </div>
        </form>
      </div>
    </section>
    </Fragment>
  );
}
export default CreateD;
