import React from 'react';
import MainNavBar from './MainNavBar';
import './HeaderIner.scss';

const HeaderIner = (props) => {
  return (
    <header id='header-iner'>
      <div className='container'>
        <MainNavBar
          contact={props.contact}
          privacy={props.privacy}
          terms={props.terms} />
      </div>
    </header>
  );
}

export default HeaderIner;
