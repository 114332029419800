import React from 'react';
import './MainNavBar.scss';
import {Link} from 'react-router-dom';

const MainNavBar = (props) => {
  const image = require(`../../../src/assets/${"honey1.png"}`);
  return (
      <nav id='main-nav'>
        <Link id='logo' to="/"><img src={image} width='30px' height='30px' alt='BSCHP'/></Link>
        <ul>
          <li><Link className={props.home} to="/">Home</Link></li>
          <li><Link className={props.contact} to="/contact">Contact</Link></li>
          <li><Link className={props.privacy} to="/privacy">Privacy Policy</Link></li>
          <li><Link className={props.terms} to="/terms">Terms Of Use</Link></li>
        </ul>
      </nav>
  );
}
export default MainNavBar;
