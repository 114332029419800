import React from 'react';
import './HeaderContent.scss';
import TypeEffect from './TypeEffect';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HeaderContent = () => {

  return (
    <div className='header-content'>
      <div className='type-effect'><TypeEffect /></div>
      <p className='new-lead'>A Smart Contract that only allows your white-list addresses to swap and sell tokens, preventing other holders from swapping and selling</p>
      <Link className='btn-main' to="/create"><b>CREATE YOUR HONEYPOT CRYPTO</b></Link>
      <div className='selling-points'>
        <div className='each-selling-point text-left'>
          <ul>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Burn, Liquidity, Staking</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Separate Buy & Sell and Transfer Tax</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Changeable Tax Fees!</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Control Who Can Sell</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Liquidity Burn</span></li>
          </ul>
        </div>
        <div className='each-selling-point text-left'>
          <ul>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Change Sell & Buy Limits</span> </li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Change Tax Fee Values</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Enable or Disable Trading</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Enable or Disable Sell Lock</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Anti-Bot and Anti-Whale mechanics!</span></li>
          </ul>
        </div>
        <div className='each-selling-point text-left'>
          <ul>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Transfer Ownership</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Airdrop Feature</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Trading Lock</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Increase Your Token Price</span></li>
            <li><FontAwesomeIcon icon="check-circle" className='fas'/><span> Rug Pull Option</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HeaderContent;
