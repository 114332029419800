import React from 'react';
// import './AboutB.scss';

const CreateA = (props) => {
  return (
    <div className='container'>
      <br />
      <h4>Progress </h4>
      <div className='progress'>
        <div style={{width: props.progress}}>{props.progress}</div>
      </div>
    </div>
  );
};

export default CreateA;
