import {useFormik} from 'formik';
import React, { Fragment } from 'react';
import './CreateC.scss';

const CreateC = ({whiteListSubmitted}) => {
  // const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  const backToTokenDetails = () => {
    whiteListSubmitted(25, false, false, false, false);
  };

  const formik = useFormik({
    initialValues: JSON.parse(sessionStorage.getItem('whiteListDetails')) ||{
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      address5: '',
      address6: '',
      address7: '',
      address8: '',
      address9: '',
      address10: '',
    },
    onSubmit: formValues => {
      sessionStorage.setItem('whiteListDetails', JSON.stringify(formValues));
      whiteListSubmitted(75, true, true, false, false);
    },
  });

  return (
    <Fragment>
      {/* {success ? <Success success={()=> setSuccess(false)}/> : null} */}
      <section id='create-c' className='text-center py-3'>
      <div className='container'>
        <h2 className='section-title'>Enter Addresses that can sell token</h2>
        <div className='bottom-line'></div>
        <form onSubmit={formik.handleSubmit}>
          <div className='text-fields'>
            <div className='text-input ' >
              <label htmlFor="address1">Wallet Address 1:</label>
              <input
                type='text'
                name='address1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address1}
                className='address1-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address2">Wallet Address 2:</label>
              <input
                type='text'
                name='address2'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address2}
                className='address2-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address3">Wallet Address 3:</label>
              <input
                type='text'
                name='address3'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address3}
                className='address3-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address4">Wallet Address 4:</label>
              <input
                type='text'
                name='address4'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address4}
                className='address4-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address5">Wallet Address 5:</label>
              <input
                type='text'
                name='address5'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address5}
                className='address5-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address6">Wallet Address 6:</label>
              <input
                type='text'
                name='address6'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address6}
                className='address6-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address7">Wallet Address 7:</label>
              <input
                type='text'
                name='address7'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address7}
                className='address7-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address8">Wallet Address 8:</label>
              <input
                type='text'
                name='address8'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address8}
                className='address8-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address9">Wallet Address 9:</label>
              <input
                type='text'
                name='address9'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address9}
                className='address9-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
            <div className='text-input ' >
              <label htmlFor="address10">Wallet Address 10:</label>
              <input
                type='text'
                name='address10'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address10}
                className='address10-input'
                placeholder='0x0000000000000000000000000000000000' />
            </div>
          </div>
          <div className='control-buttons'>
            <button type='button' className='btn-dark' onClick={backToTokenDetails}>PREVIOUS</button>
            <button type='submit' className='btn-main' >NEXT</button>
          </div>
        </form>
      </div>
    </section>
    </Fragment>
  );
}
export default CreateC;
