import React from 'react';
import { Link } from 'react-router-dom';
import './HomeB.scss';

const HomeB = () => {
  return (
    <section id='home-b' className='text-center py-2'>
      <div className='stats'>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/squid.png')} alt='Squid Game'/></li>
            <li className='stats-title'>Squid Game ($SQUID)</li>
            <li className='stats-number'>▲ 75,732,781 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/christmasfloki.png')} alt='Christmas Floki X'/></li>
            <li className='stats-title'>Christmas Floki X ($CFloki X) </li>
            <li className='stats-number'>▲ 9,571,523 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/babycate.png')} alt='Baby CateCoin'/></li>
            <li className='stats-title'>Baby CateCoin ($BABYCATE)</li>
            <li className='stats-number'>▲ 2,343,865 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/spacexdoge.png')} alt='Doge Universe'/></li>
            <li className='stats-title'>Doge Universe ($SPACEXDOGE)</li>
            <li className='stats-number'>▲ 4,752,028 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/cleanocean.png')} alt='CleanOcean'/></li>
            <li className='stats-title'>CleanOcean ($CLEAN)</li>
            <li className='stats-number'>▲ 42,104,538.86 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/2030floki.png')} alt='2030Floki'/></li>
            <li className='stats-title'>2030Floki ($2030FLOKI X) </li>
            <li className='stats-number'>▲ 148,156.33 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/lapesata.png')} alt='La Peseta'/></li>
            <li className='stats-title'>La Peseta ($PTA)</li>
            <li className='stats-number'>▲ 77,778.04 %</li>
          </ul>
        </div>
        <div>
          <ul>
            <li><img src={require('./../../../dist/img/pumpeth.png')} alt='PumpETH'/></li>
            <li className='stats-title'>PumpETH ($PETH)</li>
            <li className='stats-number'>▲ 449,850.58 %</li>
          </ul>
        </div>
      </div>
      <Link className='btn-main second-button' to="/create"><b>CREATE YOUR HONEYPOT CRYPTO NOW</b></Link>
    </section>
  );
}

export default HomeB;
