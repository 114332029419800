import React from 'react';
import './Footer.scss';

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <footer id='main-footer'>
      <div className='footer-content container'>
        <p>Powered by BSC Honey Pot</p>
        <p>Copyright &copy; {year}. All Rights Reserved</p>
      </div>
    </footer>
  );
}
export default Footer;
