import {useFormik} from 'formik';
import * as Yup from 'yup';
import React, { Fragment } from 'react';
import './CreateB.scss';
// import axios from 'axios';
// import { withRouter } from 'react-router-dom';

// import './ContactA.scss';
// import Success from '../../success/Success';

const CreateB = ({tokenDetailsSubmitted}) => {
  // const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  // useEffect(() => {
  //   if (Object.keys(formValues).length) {
  //     tokenDetailsSubmitted();
  //   };
  // const sendEmail = async () => {
  //   if (Object.keys(formValues).length) {
  //     try {
  //       await axios.post('https://cannabis-go.herokuapp.com/api/portfolio/send-email', {
  //         ...formValues
  //       });
  //       setSuccess(true);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }
  // sendEmail();
  const formik = useFormik({
    initialValues: JSON.parse(sessionStorage.getItem('tokenDetails')) || {
      name: '',
      symbol: '',
      decimals: '',
      total: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('*Required'),
      symbol: Yup.string().required('*Required'),
      decimals: Yup.number().min(1).max(18).required('*Required'),
      total: Yup.number().min(1).max(1000000000).required()
    }),
    onSubmit: formValues => {
      sessionStorage.setItem('tokenDetails', JSON.stringify(formValues));
      tokenDetailsSubmitted(50, true, false, false, false);
    },
  });

  return (
    <Fragment>
      {/* {success ? <Success success={()=> setSuccess(false)}/> : null} */}
      <section id='create-a' className='text-center py-3'>
      <div className='container'>
        <h2 className='section-title'>Enter Token Details</h2>
        <div className='bottom-line'></div>
        <form onSubmit={formik.handleSubmit}>
          <div className='text-fields'>
            <div className='text-input ' >
              <label htmlFor="name" className='required'>Token Name:</label>
              <input
                type='text'
                name='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className='name-input'
                placeholder='Name...' /> 
                {formik.touched.name && formik.errors.name ?
                  (<div className='warning'> {formik.errors.name} </div>) : null
                }
            </div>
            <div className='text-input ' >
              <label htmlFor="symbol" className='required'>Token Symbol:</label>
              <input
                type='text'
                name='symbol'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.symbol}
                className='symbol-input'
                placeholder='Symbol...' /> 
                {formik.touched.symbol && formik.errors.symbol ?
                  (<div className='warning'> {formik.errors.symbol} </div>) : null
                }
            </div>
            <div className='text-input' >
              <label htmlFor="decimals" className='required'>Decimals(Up to 18 Decimals):</label>
              <input
                type='number'
                name='decimals'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.decimals}
                className='decimal-input'
                placeholder='18' />
              {
                formik.touched.decimals && formik.errors.decimals ?
                  ( <div className='warning'> {
                      formik.errors.decimals
                    } </div>) : null
                  }
            </div>
            <div className='text-input' >
              <label htmlFor="total" className='required'>Total Supply(up to 1000000000):</label>
              <input
                type='text'
                name='total'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.total}
                className='total-input'
                placeholder='1000000000' />
                {
                  formik.touched.total && formik.errors.total ?
                  (<div className='warning'> {
                    formik.errors.total
                  } </div>) : null
                }
            </div>
          </div>
          <button type='submit' className='btn-main' >NEXT</button>
        </form>
      </div>
    </section>
    </Fragment>
  );
}
export default CreateB;
