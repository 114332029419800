import React, { Fragment } from 'react';
import './CreateE.scss';

const CreateE = ({ contractDownloaded }) => {
  
  const backToHashDetails = () => {
    contractDownloaded(75, true, true, false, false);
  }

  const backToTokenDetails = () => {
    contractDownloaded(25, false, false, false, false);
  }
  return (
    <Fragment>
      <section id='create-e' className='text-center py-3'>
        <div className='container'>
          <br />
          <p className='notice'><b>Please Wait We Are Verifying Your Transaction Hash ...</b></p>
          <br />
          <div>
            <div className='loader'></div>
          </div>
          <br />
          <form>
            <div className='control-buttons'>
              <button type='button' className='btn-dark' onClick={backToHashDetails}>PREVIOUS</button>
              <button type='button' className='btn-light' onClick={backToTokenDetails} disabled>DOWNLOAD CONTRACT</button>
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  )
}

export default CreateE;