import React, {useState} from 'react';

import HeaderIner from '../../Header/HeaderIner';
import CreateA from './CreateA';
import CreateB from './CreateB';
import CreateC from './CreateC';
import CreateD from './CreateD';
import CreateE from './CreateE';
import Footer from '../../Footer/Footer';

const Create = () => {
  const [state, setState] = useState({
    progress: '25',
    tokenDetailsSubmitted: false,
    whiteListSubmitted: false,
    transactionHashSubmitted: false,
    contractDownloaded: false
  });

  const dataReceived = (progress, tokenDetailsSubmitted,whiteListSubmitted,transactionHashSubmitted,contractDownloaded) => {
    setState({
      progress: `${progress}`,
      tokenDetailsSubmitted,
      whiteListSubmitted,
      transactionHashSubmitted,
      contractDownloaded
    })
  }

  return (
    <div>
      <HeaderIner create='current'/>
      <CreateA progress={state.progress + '%'} />
      {!state.tokenDetailsSubmitted ? <CreateB tokenDetailsSubmitted={ dataReceived }/> : null }
      {state.tokenDetailsSubmitted && !state.whiteListSubmitted ? <CreateC whiteListSubmitted={dataReceived} /> : null}
      {state.tokenDetailsSubmitted && state.whiteListSubmitted && !state.transactionHashSubmitted ?
        <CreateD transactionHashSubmitted={dataReceived} /> : null}
      {state.tokenDetailsSubmitted && state.whiteListSubmitted && state.transactionHashSubmitted ?
        <CreateE contractDownloaded={dataReceived} /> : null}
      <Footer />
    </div>
  );
}

export default Create;
