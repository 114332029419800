import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import App from './../../App';
import Privacy from '../Main/Privacy';
import Create from '../Main/Create';
import Terms from '../Main/Terms';
import Contact from '../Main/Contact';
import Success from '../success/Success'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/success" render={(props) => <Success {...props}/>} />
        <Route path="/contact" component={ Contact } />
        <Route path="/create" component={ Create } />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={ Terms } />
        <Route path="/" exact component={ App } />
      </Switch>
    </BrowserRouter>
  );
}


export default Routes;
